@font-face {
	font-family: "BlenderPro";
    src: url('./Fonts/BlenderPro-Bold.eot');
    src: url('./Fonts/BlenderPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Fonts/BlenderPro-Bold.woff2') format('woff2'),
        url('./Fonts/BlenderPro-Bold.woff') format('woff'),
        url('./Fonts/BlenderPro-Bold.ttf') format('truetype');
	/* src: local("BlenderPro"), 
			url("./Fonts/BlenderPro-Bold.ttf") format("ttf"), */
}
@font-face {
	font-family: "TradeGothic";
    src: url('./Fonts/TradeGothic-Light.eot');
    src: url('./Fonts/TradeGothic-Light.eot?#iefix') format('embedded-opentype'),
        url('./Fonts/TradeGothic-Light.woff2') format('woff2'),
        url('./Fonts/TradeGothic-Light.woff') format('woff'),
        url('./Fonts/TradeGothic-Light.ttf') format('truetype');
	/* src: local("TradeGothic"), 
			url("./Fonts/TradeGothicRegular.otf") format("otf"), */
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "TradeGothicBold";
		src: url('./Fonts/TradeGothicBold.otf') format('otf'),
		url('./Fonts/TradeGothicBold.ttf') format('truetype');
}

@font-face {
	font-family: "UbuntuR";
		src: url('./Fonts/Ubuntu-R.ttf') format('truetype');
}

:root {
  --primary: #98bf27;
  --secondary: #2c8a83;
  --gray: #f2f2f2;
  --dark-gray: #dedede;
  --text-color: #A0A0A0;
  --tertiary: #000;
  --light-teritary: #444;
}


body {
	font-family: "TradeGothic", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

/* .navbar-brand img {
  width: 200px;
} */
.aboutus{
    background-color:var(--gray);
}
.contactus{
    background-color:var(--gray);
	font-family: "TradeGothic";


}

.about-title{
    
    color:var(--primary);
}
.contactus-title{
    
    color:var(--primary);
}
.main-text{
    color:var(--text-color);
	font-family:"TradeGothic";
    
}
.bike-image{
	height: 400px;
	width: 400px;
}
.bike-parts{

	background: rgb(23,23,25);
	background: linear-gradient(180deg, rgba(23,23,25,1) 0%, rgba(5,9,10,1) 52%, rgba(26,25,32,0.896796218487395) 99%);
}

.feature{
	color:var(--gray);
}
.bike-detail{
	color:var(--gray) ;
	font-family: "BlenderPro";
}
.bike-icon{
	height: 38px;
	/* height: 40px; */

}
  .map-iframe{
	width:600px;
    height:500px;
	
  }

.my-pr-picker>p {
	margin-bottom: 0;
	font-family: inherit;
	font-size: 14px;
}
.my-pr-picker .MuiSlider-colorSuccess {
	color: var(--primary);
}
.my-pr-picker .MuiSlider-thumb {
	height: 10px;
	width: 10px;
}

.static-content strong{
	font-family: 'TradeGothicBold';
}

@media only screen and (max-width: 765px) {
	.bike-image{
		width: auto;
	}


}
.bg-gray {
	background-color: var(--gray);
}
.text-theme-primary {
	color: var(--primary);
}
.hfilter-w {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.hfilter {
	display: flex;
	align-items: center;
	font-family: "TradeGothic";
}

.search-title {
	font-family: "BlenderPro";
}
.bike-w {
	background: #fff;
	padding: 1rem;
	text-align: center;
	border: 1px solid var(--dark-gray);
	border-radius: 0.25rem;
	cursor: pointer;
	display: block;
	text-decoration: none;
}
.bike-w:hover {
	border-color: var(--secondary);
}
.bike-w img {
	/* height: 150px; */
	/* height: 235px; */
	object-fit: contain;
	margin-bottom: 1rem;
}
.bike-title {
	color: #333;
	font-family: "BlenderPro";
}
.bike-code {
	color: #333;
	font-size: 0.8rem;
	font-family: "BlenderPro";

}
.filter-box {
	background: var(--dark-gray);
	/* margin: 2rem 0; */
	padding: 1rem 2rem 0rem 2rem;
	/* padding-bottom: 1rem; */
	/* border-radius: 0.5rem; */
	font-family: "TradeGothic";

}
.filter-box label {
	margin-bottom: 1rem;
	font-family: "TradeGothic";
}
.filter-box .form-control {
	background-color: #f6f6f6;
	color: #333;
}
.feature-title{
	color:var(--primary);
	font-family: "BlenderPro";

}

.products-o{
	color: var(--primary);
	font-family:"BlenderPro"
}

.nav-link {
	font-size: 1.3rem;
}

.nav-tabs {
	--bs-nav-tabs-border-color: transparent !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	background-color: var(--gray);
	border-bottom: 2px solid var(--secondary) ;
	color:var(--tertiary) !important;
	/* border-bottom: #0a58ca !important; */
}

.nav-tabs .nav-link {
	border: transparent;
	/* color: var(--text-color) !important; */
	color: #555 !important;
	
}
.swiper-h.swiper{
	margin-bottom: calc(var(--bs-gutter-x )*2) !important;


}

.swiper-h .swiper-wrapper {
	margin-bottom: calc(var(--bs-gutter-x )*2) !important;
}

.swiper-h .swiper-button-next{
	top:100% !important;
	right: 45%;
	height: 0%;
	margin-bottom: var(--bs-gutter-x) !important;
}

.swiper-h  .swiper-button-prev {
	top:100% !important;
	left: 45%;
	height: 0%;
	/* margin-bottom: var(--bs-gutter-x ) !important; */
	margin-bottom: calc(var(--bs-gutter-x )*2) !important;

}

.swiper-button-next:after, .swiper-button-prev:after {
	font-size: 1rem !important; 
	padding: 0.7rem 1rem 0.7rem 1rem;
	border-radius: 50%;
	background-color: #fff;
	font-weight: bolder !important;
	color:var(--secondary);
}

.swiper-button-next:hover::after, .swiper-button-prev:hover::after {
	background-color: var(--secondary) !important;
	color: #fff;
}


.product-label {
	text-decoration: none;
	color: var(--text-color);

}

.instock-h {
	color: #333;
	font-size: 0.85rem !important;
}

.page-not-found {
	font-family: 'BlenderPro';
	font-size: 2rem;
}

@media only screen and (max-width: 991px) {
	.desktop-view {
		display: none;
		
	} 

	.mobile-view p {
		color: var(--text-color);
	}

	.bike-parts{

		background: var(--tertiary);
	}

	.hr-lines{
		position: relative;
		max-width: 400px;
		margin: auto;
		text-align: center;
	  }
	  
	  .hr-lines:before{
		content:" ";
		height: 3px;
		width: 100px;
		background:var(--gray);
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
	  }
	  
	  .hr-lines:after{
		content:" ";
		height: 3px;
		width: 100px;
		background: var(--gray);
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
	  }

	  .swiper-h .swiper-button-next{
		top:100% !important;
		right: 40%;
		height: 0%;
		margin-bottom: var(--bs-gutter-x) !important;
	}
	
	.swiper-h  .swiper-button-prev {
		top:100% !important;
		left: 40%;
		height: 0%;
		/* margin-bottom: var(--bs-gutter-x ) !important; */
		margin-bottom: calc(var(--bs-gutter-x )*2) !important;
	
	}

	.bike-icon{
		/* height: 45px; */
		height: 28px;
	}

  }
  @media only screen and (min-width: 992px) and (max-width: 1200px){
	.mobile-view{
		display: none;
	}

	.bike-icon{
		/* height: 45px; */
		height: 28px;
	}

	.desktop-view h5 {
			font-size: 1rem !important;
	}

	.desktop-view p {
		font-size: 0.8rem !important;
		color: var(--text-color);

	}

	.hr-lines{
		position: relative;
		max-width: 435px;
		margin: auto;
		text-align: center;
	  }
	  
	  .hr-lines:before{
		content:" ";
		height: 3px;
		width: 140px;
		background:var(--gray);
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
	  }
 
	  .hr-lines:after{
		content:" ";
		height: 3px;
		width: 140px;
		background: var(--gray);
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
	  }
} 

@media only screen and (min-width: 1200px) and (max-width: 1400px){
	.mobile-view{
		display: none;
	}

	.bike-icon{
		/* height: 45px; */
		height: 28px;
	
	}

	.desktop-view h5 {
			font-size: 1rem !important;
	}

	.desktop-view p {
		font-size: 0.8rem !important;
		color: var(--text-color);

	}

	.f-productimg img{
		/* height: 27rem; */
		height: 82vh;

		object-fit: cover;
	}

	.hr-lines{
		position: relative;
		max-width: 435px;
		margin: auto;
		text-align: center;
	  }
	  
	  .hr-lines:before{
		content:" ";
		height: 3px;
		width: 140px;
		background:var(--gray);
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
	  }
	  
	  .hr-lines:after{
		content:" ";
		height: 3px;
		width: 140px;
		background: var(--gray);
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
	  }
} 

@media screen and (min-width: 1401px) {
	.mobile-view{
		display: none;
	}

	.desktop-view h5 {
		font-size: 1rem !important;
}

	.desktop-view p {
		font-size: 0.8rem !important;
		color: var(--text-color);
	}

	.f-productimg img{
		height: 38rem;
		object-fit: cover;
	}

	.hr-lines{
		position: relative;
		max-width: 435px;
		margin: auto;
		text-align: center;
	  }
	  
	  .hr-lines:before{
		content:" ";
		height: 3px;
		width: 140px;
		background:var(--gray);
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
	  }
	  
	  .hr-lines:after{
		content:" ";
		height: 3px;
		width: 140px;
		background: var(--gray);
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
	  }
}